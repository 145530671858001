import React from 'react';
import { useProgressContext } from '../../ContextProvider/ProgressContext';
import './style.css';
import styled from 'styled-components';

const ProgressLabel = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  color: ${(props) =>
    props.paymentStatus === 'failed' && props.id === 3
      ? 'red'
      : props.status
      ? 'green'
      : props.completed
      ? 'black'
      : 'gray'};
`;

const StyledProgressBar = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;

  &:after {
    content: '';
    height: 40px;
    width: 40px;
    background: ${(props) =>
      props.status
        ? props.paymentStatus === 'failed'
          ? '#FF4D4D'
          : '#50C878'
        : 'white'};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 3px solid #829098;
    z-index: -10;
  }

  &:before {
    content: '';
    height: 10px;
    background: white;
    z-index: -9;
    width: 20px;
    border-left: 3px solid #829098;
    border-bottom: 3px solid #829098;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
    display: ${(props) => (props.completed ? 'block' : 'none')};
  }
`;

const ProgressBar = () => {
  const { progress, paymentStatus } = useProgressContext();
  console.log(progress);

  return (
    <div className="flex justify-center bg-white w-full pb-8 lg:pb-10 font-serif">
      <section className="">
        <ul className="step-wizard-list">
          {progress.map((item, i) => (
            <li key={i} className="step-wizard-item md:min-w-[150px]">
              <StyledProgressBar
                status={item.status}
                id={item.id}
                completed={item.completed}
                paymentStatus={item.id === 3 ? paymentStatus : null} // Pass payment status only for step 3
              >
                <div
                  className={`${item.status ? 'text-white' : 'text-gray-300'} ${
                    item.completed ? '-z-20' : ''
                  }`}
                >
                  {item.id}
                </div>
              </StyledProgressBar>
              <ProgressLabel
                className="hidden text-xl md:flex justify-center font-[Rajdhani]"
                status={item.status}
                completed={item.completed}
                paymentStatus={item.id === 3 ? paymentStatus : null} // Pass payment status only for step 3
                id={item.id} // Pass the step ID
              >
                {item.name}
              </ProgressLabel>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ProgressBar;