import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useProgressContext } from "../../ContextProvider/ProgressContext";
import axios from "axios";
import { jsPDF } from "jspdf";
import logo from '../../Assets/Images/uojlogo.png';  // Import the logo from the src folder

export default function Results() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveState } = useProgressContext();

  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get("transaction_id");

  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const isSuccess = paymentData?.transaction?.decision === "ACCEPT";

  useEffect(() => {
    // Handle internal state update unrelated to API
    setActiveState(3, isSuccess ? "success" : "failed");
  }, [setActiveState, isSuccess]);

  useEffect(() => {
    if (transactionId) {
      const fetchPaymentDetails = async () => {
        try {
          const response = await axios.post(
            "https://uojpay.jaffnait.com/api/payment-check",
            { transaction_id: transactionId }
          );
          setPaymentData(response.data?.data);
        } catch (error) {
          setError("Failed to fetch payment details.");
          console.error(error);
        } finally {
          setLoading(false); // Stop loading once fetch is complete
        }
      };
      fetchPaymentDetails();
    } else {
      setLoading(false);
    }
  }, [transactionId]);

  const handleButtonClick = () => {
    setActiveState(1);
    navigate("/");
  };

  const icon = isSuccess ? (
    <FaCheckCircle className="text-green-500 text-6xl" />
  ) : (
    <FaTimesCircle className="text-red-500 text-6xl" />
  );

const generatePDF = () => {
  const doc = new jsPDF();

  // Page dimensions
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Add logo to the center of the page
  const logoWidth = 150; // Adjust logo width as needed
  const logoHeight = 40; // Adjust logo height as needed
  const x = (pageWidth - logoWidth) / 2; // Center the logo horizontally
  const logoY = 20; // Vertical position of the logo
  doc.addImage(logo, 'PNG', x, logoY, logoWidth, logoHeight);

  // Add title below the logo, centered, and set size to 18 and bold
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  const title = 'Payment Receipt';
  const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const titleX = (pageWidth - titleWidth) / 2; // Center the title horizontally
  const titleY = logoY + logoHeight + 10; // Position title 10 units below the logo
  doc.text(title, titleX, titleY);

  // Add a horizontal line separator below the title
  doc.setDrawColor(0); // Black color
  doc.setLineWidth(0.2); // Thin line
  const lineY = titleY + 10; // Position line 10 units below the title
  doc.line(20, lineY, pageWidth - 20, lineY); // Line from left to right

  // Set the font for the content
  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');

  // Content inside the border
  let yOffset = lineY + 15; // Start content 15 units below the line

  // Add content dynamically using API data
  const content = [
    { label: 'Transaction ID', value: String(paymentData?.transaction?.id || "N/A") },
    { label: 'Voucher Code', value: String(paymentData?.order?.voucher_code || "N/A") },
    { label: 'Amount', value: `Rs. ${String(paymentData?.transaction?.amount || "N/A")}` },
    { label: 'Payee Name', value: String(paymentData?.order?.payee_name || "N/A") },
    { label: 'User Name', value: String(paymentData?.order?.user?.name || "N/A") },
    { label: 'Program Name', value: String(paymentData?.order?.program?.name || "N/A") },
    {
      label: 'Time',
      value: paymentData?.transaction?.signed_date_time
        ? `${new Date(paymentData.transaction.signed_date_time).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })} ${new Date(paymentData.transaction.signed_date_time).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}`
        : "N/A"
    },
  ];

  // Loop through each content line and add to the document
  content.forEach((line, index) => {
    doc.setFont('helvetica', 'bold');
    doc.text(`${line.label}:`, 20, yOffset);
    doc.setFont('helvetica', 'normal');
    doc.text(line.value, 70, yOffset);
    yOffset += 10; // Move down 10 units for the next line
  });

  // Add a horizontal line separator above the footer
  doc.setDrawColor(0); // Black color
  doc.setLineWidth(0.2); // Thin line
  doc.line(20, yOffset + 5, pageWidth - 20, yOffset + 5); // Line from left to right

  // Add footer or additional information
  doc.setFontSize(10);
  doc.setTextColor(100); // Gray color for footer
  doc.text("Thank you for your payment.", 20, yOffset + 15);
  doc.text("For any inquiries, please contact support@example.com.", 20, yOffset + 25);

  // Add a subtle page border for a professional look
  doc.setDrawColor(200); // Light gray color
  doc.setLineWidth(0.5); // Thin border
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20); // Border around the page

  // Trigger the PDF download directly
  doc.save("payment_receipt.pdf");
};

  return (
    <div>
      <div className="flex items-center justify-center my-10">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
          {loading ? (
            <div className="text-blue-500 text-xl ">Loading payment details...</div>
          ) : (
            <>
              <div className="flex justify-center">{icon}</div>
              <h1 className="text-2xl font-bold mt-4">
                {isSuccess ? "Payment Successful!" : "Payment Failed!"}
              </h1>
              <p className="text-gray-600 mt-2">
                {isSuccess
                  ? "Your payment has been processed successfully."
                  : "Your payment could not be processed. Please try again."}
              </p>
              {error && <p className="text-red-500 mt-2">{error}</p>}
              <div className="mt-6 text-left">
                {paymentData && (
                  <div className="mt-2 space-y-2">
                    <p>
                      <span className="font-medium">Transaction ID:</span>{" "}
                      <span className="text-center">{paymentData?.transaction?.id  || "N/A"}</span>
                    </p>
                    <p>
                      <span className="font-medium">Voucher Code:</span>{" "}
                      {paymentData?.order?.voucher_code || "N/A"}
                    </p>
                    <p>
                      <span className="font-medium">Amount:</span> Rs.{" "}
                      {paymentData?.transaction?.amount || "N/A"}
                    </p>
                    <p>
                      <span className="font-medium">Payee Name:</span>{" "}
                      {paymentData?.order?.payee_name || "N/A"}
                    </p>
                    <p>
                      <span className="font-medium">User Name:</span>{" "}
                      {paymentData?.order?.user?.name || "N/A"}
                    </p>
                    <p>
                      <span className="font-medium">Program Name:</span>{" "}
                      {paymentData?.order?.program?.name || "N/A"}
                    </p>
                    <p>
                      <span className="font-medium">Time:</span>{" "}
                      {new Date(paymentData?.transaction?.signed_date_time).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}{" "}
                      {new Date(paymentData?.transaction?.signed_date_time).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-4">
          <button
            className={`mt-6 ${
              isSuccess
                ? "bg-blue-500 hover:bg-blue-600"
                : "bg-red-500 hover:bg-red-600"
            } text-white px-6 py-2 rounded-lg transition duration-300`}
            onClick={handleButtonClick}
          >
            {isSuccess ? "Back to Home" : "Retry Payment"}
          </button>
          {isSuccess && (
            <button
              className="mt-6 bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg ml-4 transition duration-300"
              onClick={generatePDF}
            >
              Download PDF
            </button>
          )}
        </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
}
