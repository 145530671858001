import './App.css';
import { logo, uoj } from './Assets/index';
import FormCard from './Components/FormCard/FormCard';
import ProgressBar from './Components/ProgressBar/ProgressBar';
import Results from './Components/Results/Results';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';

import { useProgressContext } from './ContextProvider/ProgressContext';

function App() {
  

  return (
    <div className='App bg-[#E1E6FA] h-full '>
      <div className='mx-auto flex justify-center sm:p-5  p-2 shadow-lg bg-blue-500 '>
        <img
          className=' w-[700px]  p-2 px-20  rounded-lg bg-white shadow-lg '
          src={logo}
          alt='logo'
        />
      </div>
      <div className='  justify-center m-2 p-2 rounded-lg bg-white '>
        <h1
          className=' font-bold  mt-5 text-blue-900 text-xl lg:text-2xl tracking-widest uppercase '
          style={{ textShadow: '' }}
        >
          University of Jaffna Payment
        </h1>
       
        <ProgressBar />
      </div>
      <div
        className='m-2 rounded-lg shadow-lg bg-black bg-opacity-50'
        style={{ backgroundImage: `url(${uoj})`, backgroundSize: 'cover' }}
      >
        <div className='p-2 sm:p-2 '>
          
          <Router>
      <Routes>
        
      </Routes>
    </Router>
    <Router>
      <Routes>
        <Route path="/:status" element={<Results />} />
        <Route path="/" element={<FormCard  />} />
        
      </Routes>

    </Router>
        </div>
      </div>
      <div className='bg-white p-5 font-Agbalumo'>
        <h1>
          Copyright © 2023 - UOJ Payment | Speed IT Net - All Rights Reserved
        </h1>
      </div>
    </div>
  );
}

export default App;
