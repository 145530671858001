import React, { createContext, useState, useContext } from 'react';

const InitialValue = [
  {
    name: 'Fill your details',
    id: 1,
    status: true,
    completed: false,
  },
  {
    name: 'Verify details',
    id: 2,
    status: false,
    completed: false,
  },
  {
    name: 'Payment status',
    id: 3,
    status: false,
    completed: false,
  },
];

const ProgressContext = createContext();

export const useProgressContext = () => useContext(ProgressContext);

const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(InitialValue);
  const [paymentStatus, setPaymentStatus] = useState(null); // 'success' or 'failed'

  const setActiveState = (stateId, paymentStatus = null) => {
    setProgress((prevProgress) =>
      prevProgress.map((step, index) => ({
        ...step,
        status: step.id === stateId,
        completed: index < stateId - 1,
      }))
    );
    setPaymentStatus(paymentStatus); // Update payment status
  };

  const getActiveState = () => {
    return progress.find((step) => step.status);
  };

  return (
    <ProgressContext.Provider
      value={{ progress, setActiveState, getActiveState, paymentStatus }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressProvider;